import React from 'react';
import "./Badges.scss";

const Badges = () => {
    return (
        <div className='badges'>
            <div className="pending">
                pending
            </div>
        </div>
    );
};

export default Badges;