import React from 'react';
import ProductTable from '../../AdminPannel/components/ProductTable/ProductTable';

const ProductList = () => {
    return (
        <div>
            <h1>All Products</h1>
            <ProductTable></ProductTable>
        </div>
    );
};

export default ProductList;