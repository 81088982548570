import React, { useEffect, useState } from "react";
import RelatedProduct from "../RelatedProduct/RelatedProduct";
import Counter from "../Counter/Counter";
import "./ProductDetails.scss";
import Button from "../Button/Button";
import { HiShoppingCart } from "react-icons/hi";
import {
  BsTwitter,
  BsFacebook,
  BsInstagram,
  BsPinterest,
} from "react-icons/bs";
import { useGetSingleProductQuery } from "../../features/product/productApi";
import { Link, useParams } from "react-router-dom";
import { addToDb } from "../../utilities/localStorage";
import { useDispatch } from "react-redux";
import {
  handleClose,
  searchClose,
} from "../../features/cartHandler/cartHandler";
import { SideBySideMagnifier } from "react-image-magnifiers";
import Footer from "../Footer/Footer";
import DetailsSkeleton from "../LoadingSkeleton/DetailsSkeleton/DetailsSkeleton";

const ProductDetails = () => {
  const { productId } = useParams();
  const { data, isLoading, isSuccess } = useGetSingleProductQuery(productId);
  const {
    name,
    description,
    price,
    discount,
    category,
    stockAvailable,
    video,
  } = data?.description || {};
  const { _id, images } = data || {};

  const dispatch = useDispatch();

  const [mainImage, setMainImage] = useState(images?.[0]);

  useEffect(() => {
    setMainImage(images?.[0]);
  }, [images]);

  useEffect(() => {
    dispatch(handleClose());
    dispatch(searchClose());
    window.scrollTo(0, 0);
  }, [dispatch]);

  const handleAction = (_id) => {
    addToDb(_id);
  };

  return (
    <div className="productDetails">
      {isLoading && <DetailsSkeleton />}
      {!isLoading && (
        <div className="productDetails_container">
          {/* <Tags></Tags> */}
          <div className="product_display">
            <div className="image_container">
              <div className="display_image">
                <div className="side-image">
                  {isSuccess &&
                    images?.length > 0 &&
                    images?.map((item, index) => (
                      <img
                        key={index}
                        src={`https://backend.thespectacle23.com/images/${item.filename}`}
                        onClick={() => setMainImage(item)}
                        alt=""
                      />
                    ))}
                </div>
                <div className="main-image">
                  <SideBySideMagnifier
                    alwaysInPlace={true}
                    imageSrc={`https://backend.thespectacle23.com/images/${mainImage?.filename}`}
                    imageAlt="Example"
                  />
                </div>
              </div>
            </div>
            <div className="display_content">
              <div className="display_content_top">
                <h2>{name}</h2>
                <span>REGULAR PRICE: {price} Taka</span>
                <h3>Discount Price: {price - discount} Taka</h3>
              </div>
              {stockAvailable === "true" && (
                
                <div className="displayContent_action">
                  
                  <Counter id={_id}></Counter>
                  <Link to="/checkout">
                    <Button>
                      <HiShoppingCart /> Checkout
                    </Button>
                  </Link>
                </div>
              )}
              {
                stockAvailable ==="false" && <div className="productDetails_stockOut">STOCK OUT</div>
              }

              <div
                className=" details_description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
              <div className="product_category_and_share">
                <div>
                  <h4>Category: </h4>
                  <p>Eye Glass</p>
                </div>
                <div>
                  <h4>Share: </h4>
                  <BsFacebook></BsFacebook>
                  <BsTwitter></BsTwitter>
                  <BsInstagram></BsInstagram>
                  <BsPinterest></BsPinterest>
                </div>
              </div>
            </div>
          </div>
          
          {
            video && <div
            className="video-responsive"
            dangerouslySetInnerHTML={{ __html: video }}
          ></div>
          }
          <div className="product_heading">
            <h2>Related Product:</h2>
          </div>

          <RelatedProduct category={category}></RelatedProduct>
          <Footer></Footer>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
